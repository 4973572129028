import React, { useState } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import cls from 'classnames';

import { Tag, TagSize, Text, TextTypography } from 'wix-ui-tpa';

import { Box } from 'common/components/Box/Box';
import { AuthorInfo } from 'Group/Widget/Discussion/AuthorInfo';
import { PinIcon } from 'Group/Widget/icons/PinIcon';
import { IFeedItem } from 'FeedWidget/api/types';
import { IControllerActions } from 'FeedWidget/types';
import settingsParams from 'FeedWidget/settingsParams';

import { Reactions } from './Reactions';
import { FeedItemComments } from './Comments';
import { FeedItemContent } from './FeedItemContent';
import { FeedItemActions } from './FeedItemActions';

import { classes } from './FeedItem.st.css';

interface FeedItemProps extends IControllerActions {
  item: IFeedItem;
  contextId: string;

  onGuard(): void;
}

export const FeedItem = React.memo((props: FeedItemProps) => {
  const { item, contextId, methods } = props;
  const { t } = useTranslation();
  const settings = useSettings();
  const [showComments, setShowComments] = useState(
    settings.get(settingsParams.showComments),
  );

  const [topic] = item.entity?.topics;

  return (
    <Box withSideBorders className={classes.root}>
      {!!item.pin && (
        <div className={classes.pinnedHeader}>
          <PinIcon className={classes.icon} width={18} height={17} />
          <Text className={classes.headerText}>
            {t('groups-web.feed.pined-post')}
          </Text>
        </div>
      )}
      <div className={classes.meta}>
        <AuthorInfo
          activity={item.activity}
          name={item.createdBy.name || t('groups-web.member.anonymous')}
          avatarUrl={item.createdBy.imageUrl}
          timeStamp={item.createdAt}
          relationship={item.createdBy.role?.value}
          roles={[]}
        />
        <FeedItemActions methods={methods} item={item} />
      </div>
      <FeedItemContent item={item} />
      {topic?.displayName && (
        <Tag
          size={TagSize.small}
          className={classes.postTopic}
          onClick={() =>
            methods.group.redirectToGroup([['entity.topics', topic.id!]])
          }
        >
          {topic.displayName}
        </Tag>
      )}

      {settings.get(settingsParams.showReactions) && (
        <div className={classes.reactions}>
          <Reactions
            reactions={item.reactions}
            react={handleReact}
            unreact={handleUnreact}
          />

          <div
            className={cls(classes.commentsCounter, {
              [classes.disabled]: !settings.get(settingsParams.showComments),
            })}
            onClick={toggleComments}
          >
            <Text
              className={classes.totalComments}
              typography={TextTypography.listText}
            >
              {t('groups-web.feed.comments.total', {
                count: item.comments?.total,
              })}
            </Text>
          </div>
        </div>
      )}
      {showComments && (
        <FeedItemComments feedItemId={item.feedItemId} contextId={contextId} />
      )}
    </Box>
  );

  function toggleComments() {
    if (settings.get(settingsParams.showComments)) {
      setShowComments(!showComments);
    }
  }

  function handleReact(code: string) {
    if (!item.permissions?.canReact) {
      return props.onGuard();
    }

    return methods.feed.react(item.feedItemId!, code);
  }

  function handleUnreact(code: string) {
    if (!item.permissions?.canReact) {
      return props.onGuard();
    }

    return methods.feed.unreact(item.feedItemId!, code);
  }
});

FeedItem.displayName = 'FeedItem';
