import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { EmptyState } from 'Group/Widget/EmptyState/EmptyState';

import { EFeedStatus } from 'FeedWidget/store/feed/types';
import { feedSelectors } from 'FeedWidget/store/feed/adapter';
import { IFeedItem } from 'FeedWidget/api/types';
import { IControllerActions } from 'FeedWidget/types';
import settingsParams from 'FeedWidget/settingsParams';

import { useDidUpdate } from 'common/hooks';

import { Footer, Item, List, ScrollSeekPlaceholder } from './virtual';
import { FeedItem, FeedItemSkeleton } from './FeedItem';
import { feedSelector } from './selectors';

interface FeedProps extends IControllerActions {
  groupId: string;

  onGuard(): void;
}

export const Feed: React.FC<FeedProps> = (props) => {
  const { groupId, onGuard, methods } = props;
  const { isPreview, isSSR } = useEnvironment();

  const settings = useSettings();
  const { t } = useTranslation();

  const state = useSelector(feedSelector);
  const postsToShow = settings.get(settingsParams.postsCount);
  const limit = !!postsToShow;

  const items = feedSelectors.selectAll(state);
  const hasMore = state.nextCursor;

  const computeItemKey = useCallback(
    (index: number, item: IFeedItem) => item.feedItemId!,
    [],
  );

  useDidUpdate(() => {
    methods.feed.fetch(groupId, postsToShow);
  }, [groupId, isPreview, postsToShow]);

  if ([EFeedStatus.init, EFeedStatus.loading].includes(state.status)) {
    return (
      <>
        <FeedItemSkeleton />
        <FeedItemSkeleton />
        <FeedItemSkeleton />
      </>
    );
  }

  if (!items.length) {
    return (
      <EmptyState
        title={t('groups-web.feed.empty.label')}
        content={t('groups-web.feed.empty.description')}
        boxProps={{
          withSideBorders: true,
        }}
        button={{
          label: t('groups-web.go-to-group.label'),
          onClick: methods.group.redirectToGroup,
        }}
      />
    );
  }

  return (
    <Virtuoso
      useWindowScroll
      data={items}
      endReached={handleLoadMore}
      computeItemKey={computeItemKey}
      initialItemCount={isSSR ? items.length : undefined}
      overscan={300}
      totalCount={postsToShow}
      components={{
        List,
        ScrollSeekPlaceholder,
        Item,
        Footer: () => <Footer methods={methods} />,
      }}
      itemContent={(_, item: IFeedItem) => (
        <FeedItem
          methods={methods}
          onGuard={onGuard}
          item={item}
          contextId={state.groupId!}
        />
      )}
    />
  );

  function handleLoadMore() {
    if (hasMore && !limit) {
      methods.feed.fetchMore(10);
    }
  }
};

Feed.displayName = 'Feed';
